import React, {Component} from 'react';




class CategoryPill extends Component {

    render() {



        let category;

        if (this.props.category === "Research") {
            category = <span className="text-white rounded bg-green-600 px-2 py-1 text-xs">#Research</span>
        }
        else if(this.props.category === "Stories") {
            category = <span className="text-white rounded bg-indigo-600 px-2 py-1 text-xs">#Stories</span>
        }
        else if(this.props.category === "News") {
            category = <span className="text-white rounded bg-pink-600 px-2 py-1 text-xs">#News</span>
        }
        else if(this.props.category === "E-commerce Challenge") {
            category = <span className="text-white rounded bg-indigo-600 px-2 py-1 text-xs">#E-commerce Challenge</span>
        }
        else {
            category = <span className="text-white rounded bg-yellow-600 px-2 py-1 text-xs">#{this.props.category}</span>
        }



       return (
           category
        )
    }





}

export default CategoryPill;



