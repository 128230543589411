import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp'
import {useFormik} from "formik";



const validate = values => {
    const errors = {};
    const res = values.email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    if (!values.email) {
        errors.email = 'Email is required';
    } else if (res === null) {
        errors.email = 'Check if you entered a valid email.';
    }

    return errors;
};

const EmailForm = ({}) => {

    const formik = useFormik({
        initialValues: {
          email: '',
        },
        validate,


    onSubmit: (values, { setSubmitting }, actions) => {

        setTimeout(() => {
          setSubmitting(false);
          formik.resetForm();

          addToMailchimp(values.email).then(data => {
      // I recommend setting data to React state
      // but you can do whatever you want (including ignoring this `then()` altogether)
      console.log(data)
    })


        }, 2000);
      }},
      );

    let strings;
    if (formik.isSubmitting){
        strings = 'Joining..'
    }
    else {
        strings = 'Submit'
    }


      return(
          <form onSubmit={formik.handleSubmit} className="emailForm">
          <div className="flex lg:flex-row flex-col">

          <div className="w-full lg:w-3/4 mb-2 lg:mb-0">
            <input type="text" className="h-8 px-3 text-sm placeholder-gray-300 text-pink-600 bg-gray-900 outline-none focus:outline-none focus:shadow-outline w-full border-solid border border-pink-600 rounded" value={formik.values.email}
                   id="email"
                   name="email"
                   onChange={formik.handleChange}
                   placeholder="  email@example.com"     />
            {formik.errors.email ? <div className="text-xs"><span className="flex justify-start ml-1 mt-2 text-red-500">{formik.errors.email}</span>
             </div> : null}
          </div>

            <div className="flex flex-col lg:flex-row w-full lg:w-1/4 lg:ml-4">
                <button className="h-8 hover:bg-pink-800 bg-pink-600 px-8 rounded" type="submit" disabled={formik.isSubmitting}><span className="text-sm text-white">{strings}</span></button>
            </div>


                      </div>

        </form>
      )


}




export default EmailForm;